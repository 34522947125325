import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { NotFoundPageComponent } from './shared/pages/not-found-page/not-found-page.component';
import { TermsPageComponent } from './shared/pages/terms-page/terms-page.component';
import { MetadataService } from './services/metadata/metadata.services';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule),
    canActivate: [MetadataService]
  },

  {
    path: "dashboard",
    loadChildren: () => import("./questionary/questionary.module").then(m => m.QuestionaryModule),
    canActivate: [MetadataService]
  },

  {
    path: "dashboard",
    loadChildren: () => import("./user/user.module").then(m => m.UserModule),
    canActivate: [MetadataService]
  },

  { path: "terms-and-conditions", component: TermsPageComponent },

  {
    path: "maintenance-mode",
    component: MaintenancePageComponent,
    // canActivate: [MetadataService]
  },

  { path: '404', component: NotFoundPageComponent },

  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
