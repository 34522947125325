import { ToastService } from 'src/app/utils/toast.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IRoom } from 'src/app/models/rooms';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-show-room',
  templateUrl: './show-room.component.html',
  styleUrls: ['./show-room.component.scss']
})
export class ShowRoomComponent implements OnInit {
  @Output() loadRooms = new EventEmitter();
  @Output() loadNotifications = new EventEmitter();
  @Input('mainColor') mainColor: string = ''
  @Input('haveNotifications') haveNotifications: boolean = false
  @Input('room') room!: IRoom

  inProcess: boolean = false
  selectedStudents: Array<any> = []
  notifications: Array<any> = []

  constructor(
    private modalService: NgbModal,
    private roomsService: RoomsService,
    private errorsService: ErrorsService,
    private toastService: ToastService,

  ) { }

  ngOnInit(): void {

  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true, scrollable: true
    })
    this.getPetitions()
  }

  joinRequestHandler(answer: boolean) {

    const observables: Array<any> = [];
    let checked = 0

    this.notifications.forEach((notification: any, i: number) => {
      if (notification.checked) {
        observables.push(this.roomsService.answerRequestToJoin(notification.id, { answer: answer }))
        checked += 1
      }
    });

    if (checked) {
      this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    } else {
      this.toastService.showNotificationModal('Seleccione al menos un estudiante para continuar el proceso', '');
    }

    forkJoin(observables).subscribe({
      next: (data) => {
        this.toastService.closeLoader();
        this.loadRooms.emit()
        this.modalService.dismissAll()
      },
      error: (e) => {
        this.toastService.closeLoader();
        this.errorsService.handleErrors(e)
      },
      complete: () => {
        this.loadNotifications.emit()
      }
    })

  }

  getPetitions() {
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    this.roomsService.getPetitions(this.room.id!).subscribe({
      next: (data) => {
        this.toastService.closeLoader()
        this.notifications = data
        this.deselectAll()
      },
      error: (e) => {
        this.toastService.closeLoader()
        this.errorsService.handleErrors(e)
      }
    })
  }

  selectStudent(notificationIndex: number) {
    this.notifications[notificationIndex].checked = !this.notifications[notificationIndex].checked
  }
  deselectAll() {
    this.notifications.forEach(notification => {
      notification.checked = false
    });
  }
  selectAll() {
    this.notifications.forEach(notification => {
      notification.checked = !notification.checked
    });
  }

}
