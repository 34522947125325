import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { InvoicePreviewComponent } from '../user/components/invoice-preview/invoice-preview.component';
import { ModalComponent } from '../shared/components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  loaderModalRef:any;

  constructor(private toastr: ToastrService,public modalService:NgbModal,public activeModal: NgbActiveModal) {}

  showToastSuccess(message: string,title:string): void {
    this.toastr.success(message, title,{
        closeButton:true,
    })
  }

  showNotificationModal(title:string,message:string,type?:string,action?:string, routeTo?: string){
    let modalRef = this.modalService.open(ModalComponent,{
        centered: true,
        backdropClass: 'cp-backdrop-notification',
        modalDialogClass: 'cp-dialog',
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = message;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.action = action ? action : 'Ok. Entendido';
    modalRef.componentInstance.routeTo = routeTo
  }

  showLoaderModal(title:string,message:string){
    this.loaderModalRef = this.modalService.open(LoaderComponent,{
        centered: true,
        backdrop:'static',
        keyboard:false,
        backdropClass: 'cp-backdrop-notification',
        modalDialogClass: 'cp-dialog',
    });
    this.loaderModalRef.componentInstance.title = title;
    this.loaderModalRef.componentInstance.content = message;
  }

  closeLoader() {
    this.loaderModalRef.close();
  }

  showInvoiceModal(invoice_data:any){
    let modalRef = this.modalService.open(InvoicePreviewComponent,{
        size:'lg',
        centered: true,
        backdropClass: 'cp-backdrop-notification',
        modalDialogClass: 'cp-dialog',
    });
    modalRef.componentInstance.content = invoice_data;
  }

}
