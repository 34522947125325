<div class="list-charts">
  <!-- INTERESES -->
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <span class="font font-16 font-primary--b text-blue">Intereses Vocacionales</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Existen diferentes ámbitos o campos a los que pertenecen innumerables profesiones o tareas profesionales.
            Algunos de
            estos intereses nos llaman más la atención o notamos que parece que se adaptan más a nuestras inquietudes o
            motivaciones</p>
        </div>
      </div>
    </h2>
    <div class="chart-container chart-flex-container">
      <app-radar-chart description="Existen diferentes ámbitos o campos a los que pertenecen innumerables profesiones o tareas profesionales.
            Algunos de
            estos intereses nos llaman más la atención o notamos que parece que se adaptan más a nuestras inquietudes o
            motivaciones" [wideMode]="true" [subscribed]="true" [chartCategory]="'vocational_interests'"
        [chartId]="'vocational_interests'">
      </app-radar-chart>
    </div>
  </div>
  <!-- Aptitudes -->
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3 mb-0">
      <img *ngIf="!is_subscribed" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
      <span class="font font-16 font-primary--b text-blue">Perfiles Profesionales</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>El algoritmo, tras analizar tus respuestas en los diferentes pasos, elaborará tus aptitudes profesionales
            con el
            objetivo de que seas consciente cuáles de ellas están más desarrolladas en ti</p>
        </div>
      </div>
    </h2>
    <div class="chart-container full-height">
      <app-doughnut-chart description="El algoritmo, tras analizar tus respuestas en los diferentes pasos, elaborará tus aptitudes profesionales
            con el
            objetivo de que seas consciente cuáles de ellas están más desarrolladas en ti" [wideMode]="true"
        [subscribed]="is_subscribed" [chartId]="'profesionalChart'" [classOverlay]="true" [stats]="true"
        [chartCategory]="'professional_fields'">
      </app-doughnut-chart>
    </div>
  </div>
  <!-- Personalidad -->
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <img *ngIf="!is_subscribed" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
      <span class="font font-16 font-primary--b text-blue">Personalidad</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Poseer datos acerca de tu personalidad, saber cuáles son tus puntos fuertes y aquello
            que
            puedes mejorar es un punto de partida que te colocará en una posición ventajosa frente a otras personas que
            lo
            desconocen y por tanto tu probabilidad de acertar en tus elecciones será mayor</p>
        </div>
      </div>
    </h2>
    <div class="chart-container w-70 full-height chart-flex-container">
      <app-radar-chart [wideMode]="true" [subscribed]="is_subscribed" description="Poseer datos acerca de tu personalidad, saber cuáles son tus puntos fuertes y aquello
            que
            puedes mejorar es un punto de partida que te colocará en una posición ventajosa frente a otras personas que
            lo
            desconocen y por tanto tu probabilidad de acertar en tus elecciones será mayor"
        [chartCategory]="'personalities'" [chartId]="'personality_chart'">
      </app-radar-chart>
    </div>
  </div>
  <!-- Inteligencias -->
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <img *ngIf="!is_subscribed" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
      <span class="font font-16 font-primary--b text-blue">Inteligencias Múltiples (GARDNER)</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>La Teoría de las Inteligencias Múltiples habla de que cada uno de nosotros tiene algunas partes del cerebro
            más
            desarrolladas, por tanto, se podrían decir que son más inteligentes que otras personas realizando unas
            determinadas
            actividades</p>
        </div>
      </div>
    </h2>
    <div class="chart-container full-height chart-md-container multipleIntelligences">
      <app-doughnut-chart description="La Teoría de las Inteligencias Múltiples habla de que cada uno de nosotros tiene algunas partes del cerebro
            más
            desarrolladas, por tanto, se podrían decir que son más inteligentes que otras personas realizando unas
            determinadas
            actividades" [wideMode]="true" [subscribed]="is_subscribed" [chartId]="'multipleIntelligences'"
        [classOverlay]="false" [classOverlayMultiple]="true" [stats]="true" [chartHeight]="'300px'"
        [chartCategory]="'multiple_intelligences'">
      </app-doughnut-chart>
    </div>
  </div>
  <!-- Emocional -->
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <img *ngIf="!is_subscribed" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
      <span class="font font-16 font-primary--b text-blue">Inteligencia Emocional</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Nuestro mundo emocional se pone en juego en cada cosa que hacemos o decimos, no hay nada en este mundo que
            no nos afecte
            a nivel interior, y saber entender qué está ocurriendo y qué tenemos que hacer con eso es primordial para
            nuestra propia
            felicidad</p>
        </div>
      </div>
    </h2>
    <!-- style="width: 84%;margin-left:8%;margin-top: -40px;" -->
    <div class="chart-container full-height chart-md-container">
      <app-polar-chart description="Nuestro mundo emocional se pone en juego en cada cosa que hacemos o decimos, no hay nada en este mundo que
            no nos afecte
            a nivel interior, y saber entender qué está ocurriendo y qué tenemos que hacer con eso es primordial para
            nuestra propia
            felicidad" [wideMode]="true" [subscribed]="is_subscribed" [chartCategory]="'intelligences'"
        [chartId]="'intelligences'">
      </app-polar-chart>
    </div>
  </div>
  <!-- Estilos de aprendizaje -->
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <img *ngIf="!is_subscribed" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
      <span class="font font-16 font-primary--b text-blue">Estilos de aprendizaje (Honey y Mumford)</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Los estilos de aprendizaje son los diferentes modos que tienen las personas de utilizar su inteligencia. Es
            tan importante lo que aprendes como el modo en el que lo expresas y lo sabes transmitir a los demás.</p>
        </div>
      </div>
    </h2>
    <!-- style="width: 84%;margin-left:8%;margin-top:0px;" -->
    <div class="chart-container learning_styles">
      <app-bar-chart description="Los estilos de aprendizaje son los diferentes modos que tienen las personas de utilizar su inteligencia. Es
            tan importante lo que aprendes como el modo en el que lo expresas y lo sabes transmitir a los demás."
        [chartCategory]="'learning_styles'" [wideMode]="true" [subscribed]="is_subscribed"
        [chartId]="'learning_styles'">
      </app-bar-chart>
      <div class="custom-legend">
        <div class="group-one">
          <p><i class="bi bi-circle-fill font font-10 text-green"></i>&nbsp; Activo</p>
          <p><i class="bi bi-circle-fill font font-10 text-red"></i>&nbsp; Reflexivo</p>
        </div>
        <div class="group-two">
          <p><i class="bi bi-circle-fill font font-10 text-orange"></i>&nbsp; Teórico</p>
          <p><i class="bi bi-circle-fill font font-10" style="color: #0058FF;"></i>&nbsp; Pragmático</p>
        </div>
      </div>
    </div>
  </div>
</div>