import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

    public chartData:any;

    constructor(
        private httpClient: HttpClient,
    ) { }

    setChartsData(data:any){
        this.chartData = data;
    }
    
    setDummyChartData(){
        this.chartData = {
            "id": 50,
            "vocational_interests": {
                "id": 1,
                "technical": 4.0,
                "investigator": 4.0,
                "entrepreneur": 4.0,
                "communicator": 4.0,
                "analyst": 4.0
            },
            "multiple_intelligences": {
                "id": 1,
                "linguistic": 20.0,
                "mathematical_logician": 50.0,
                "space": 30.0,
                "cinematic_bodily": 40.0,
                "musical": 10.0,
                "intrapersonal": 20.0,
                "interpersonal": 30.0,
                "naturalist": 40.0
            },
            "professional_fields": {
                "id": 1,
                "manual_technician": 8.0,
                "research_scientist": 10.0,
                "creative_artistic": 12.0,
                "social_healthcare": 9.0,
                "business_persuasive": 7.0,
                "management":3.0,
                "virtual_digital_streamer": 8.0
            },
            "personalities": {
                "id": 1,
                "perfectionist": 12.0,
                "altruistic": 11.0,
                "active": 5.0,
                "affective": 7.0,
                "rational": 8.0,
                "skeptical": 9.0,
                "enthusiastic": 10.0,
                "leader": 6.0,
                "mediator": 4.0
            },
            "avks": {
                "id": 1,
                "auditory": 0.0,
                "visual": 0.0,
                "kinesthetic": 0.0
            },
            "learning_styles": {
                "id": 1,
                "active": 90.0,
                "thoughtful": 60.0,
                "theoretical": 70.0,
                "pragmatic": 80.0
            },
            "intelligences": {
                "id": 1,
                "self_awareness": 10.0,
                "empathy": 15.0,
                "self_confidence": 12.0,
                "motivation": 8.0,
                "self_control": 5.0,
                "social_skills": 6.0
            },
            "interest_options": {
                "id": 1,
                "technical_with_academic_option": 0.0,
                "technical_inclination": 0.0
            },
            "competencies": {
                "id": 1,
                "self_study": 0.0,
                "self_critical_capacity": 0.0,
                "information_search": 0.0,
                "time_organization": 0.0,
                "social_skills": 0.0,
                "project_management": 0.0,
                "adaptability": 0.0,
                "communication_skills": 0.0,
                "information_treatment": 0.0,
                "informatic_training": 0.0,
                "it_level_used": 0.0
            }
        }
    }

    getChartDataByCategory(category:any){
        let chart_data = this.chartData[category];
        let array_data: unknown[] = [];
        for (let [key, value] of Object.entries(chart_data)) {
            if(key !== 'id') array_data.push(value);
        }
        return array_data
    }

}
