<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="font-primary--b font-18" id="modal-basic-title">Gestión de cookies</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="mb-3">
      Los cookies <span class="font-primary--b">Gr_refresh</span> y <span class="font-primary--b">Gr_access</span> son
      obligatorios para una experiencia correcta en GrowthRoad. Aquí, te damos la
      posibilidad de gestionar los cookies opcionales, listados a continuación:
    </p>
    <ul>
      <li class="d-flex justify-content-between" *ngFor="let cookie of cookies; index as idx">
        <p class="font-primary--eb text-capitalize">
          {{cookie.title}} <span *ngIf="!cookie.isOptional">
            <sup class="cursor-pointer" title="Este cookie es obligatorio">📄</sup>
          </span>
        </p>
        <p>
          <app-toggle-switch *ngIf="cookie.isOptional" [active]="cookie.status"
            (click)="toggleCookieHandler(cookie.id, idx)">
          </app-toggle-switch>
        </p>
      </li>
    </ul>
    <div class="mt-3">
      <a href="https://www.growthroadgroup.com/cookie-policy" target="_blank">Política de cookies</a>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn bg-transparent text-red" (click)="disableAllCookies()">Desactivar Todos</button>
    <button type="button" class="btn bg-transparent text-red" (click)="enableAllCookies()">Aceptar Todos</button>
    <button type="button" class="btn bg-red text-white" (click)="save()">Guardar</button>
  </div>
</ng-template>
<div class="cookies-component" [class.show]="gpdrBlock">
  <div class="content">
    <p class="text-white">
      Al hacer uso de nuestro sitio, estás aceptando el uso de cookies para mejorar tu experiencia, así como nuestros
      <a href="https://www.growthroadgroup.com/terms-conditions" target="_blank" class="text-white">términos y
        condiciones</a> y <a href="https://www.growthroadgroup.com/privacy-policy" target="_blank"
        class="text-white">políticas de privacidad</a>.

    </p>
    <button class="btn bg-white text-red font-primary--b" (click)="toggleGpdrBlock()">Entendido</button>
    <button class="btn bg-white text-red font-primary--b" (click)="open(content)">Gestionar Cookies</button>
  </div>
</div>