<div class="graph-wrapper" [class.unsubscribed]="!subscribed" [class.wideMode]="wideMode">
  <canvas #{{chartId}} id="{{ chartId }}"></canvas>
  <div class="custom-legend">
    <div class="legend" *ngFor="let label of labels; index as i">
      <div class="block" [ngStyle]="{backgroundColor: colors.background[i]}"></div>
      <p class="label">
        {{label}}
      </p>
    </div>
  </div>
  <div class="unsubscribed-card" *ngIf="!subscribed">
    <p class="description">
      {{description}}
    </p>
    <div class="text-end">
      <a class="mt-2 ms-auto d-block text-red" routerLink="/dashboard/membership">Desbloquear Estadísticas</a>
    </div>
  </div>
</div>
