<main class="dashboard-layout bg-neutral">
  <app-drawer></app-drawer>

  <section class="content-wrapper">
    <router-outlet></router-outlet>
  </section>

  <app-gpdr></app-gpdr>

</main>
