import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Icookie } from 'src/app/models/cookies.models';

@Component({
  selector: 'app-gpdr',
  templateUrl: './gpdr.component.html',
  styleUrls: ['./gpdr.component.scss']
})
export class GpdrComponent implements OnInit {
  gpdrBlock: boolean = true
  cookies: Array<Icookie> = [
    {
      id: '1',
      title: 'gr_refresh',
      status: true,
      description: '',
      isOptional: false
    },
    {
      id: '2',
      title: 'gr_access',
      status: true,
      description: '',
      isOptional: false
    },
    {
      id: '3',
      title: 'Google Analitycs',
      status: true,
      description: '',
      isOptional: true
    },
  ]

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getCookieSettings()

    if (localStorage.getItem("show_gdpr")) {
      this.gpdrBlock = JSON.parse(localStorage.getItem("show_gdpr")!);
    }
  }

  open(content: any) {
    this.modalService.open(content, { centered: true });
  }
  closeModals() {
    this.modalService.dismissAll()
  }

  toggleGpdrBlock() {
    localStorage.setItem('show_gdpr', JSON.stringify(!this.gpdrBlock))
    this.gpdrBlock = !this.gpdrBlock
  }

  enableAllCookies() {
    this.cookies.forEach(cookie => {
      if (cookie.isOptional) {
        cookie.status = true
      }
    });
    console.debug(this.cookies)
  }

  disableAllCookies() {
    this.cookies.forEach(cookie => {
      if (cookie.isOptional) {
        cookie.status = false
      }
    });
    console.debug(this.cookies)
  }

  toggleCookieHandler(cookieId: string, index: number) {

    if (this.cookies[index].id === cookieId) {
      this.cookies[index].status = !this.cookies[index].status
    }
  }

  getCookieSettings() {
    if (localStorage.getItem("cookies_settings")) {
      const storedCookies = JSON.parse(localStorage.getItem("cookies_settings")!);
      this.cookies = storedCookies
    } else {
      localStorage.setItem('cookies_settings', JSON.stringify(this.cookies))
    }
  }

  save() {
    // localStorage.setItem("names", JSON.stringify(names));
    // var storedNames = JSON.parse(localStorage.getItem("names"));
    localStorage.setItem('cookies_settings', JSON.stringify(this.cookies))
    this.closeModals()
  }

}
