import { ToastService } from 'src/app/utils/toast.service';
import { AuthService } from 'src/app/auth.service';
import { UserService } from './../../../services/users/users.service';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRoom } from 'src/app/models/rooms';
import { ErrorsService } from 'src/app/services/errors.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss']
})
export class EditRoomComponent implements OnInit {
  @Output() loadRooms = new EventEmitter();
  @Input('room') room: IRoom = {
    name: '',
    organization: null
  }

  inProcess: boolean = false
  user_data: any


  constructor(
    private modalService: NgbModal,
    private roomService: RoomsService,
    private errorsService: ErrorsService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  editRoom() {
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    this.inProcess = true
    this.roomService.editRoom(this.room).subscribe({
      next: (data) => {
        this.toastService.closeLoader()
        this.inProcess = false
        this.loadRooms.emit()
      },
      error: (e) => {
        this.toastService.closeLoader()
        this.errorsService.handleErrors(e)
      }
    })
  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true,
    })
  }

}
