import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  
  @Input() content: any;
  @Input() title: any;
  @Input() type: any;

  constructor(private modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
