import type { MetaDefinition } from "@angular/platform-browser"

interface ISEO {
  TITLE: string
  SITE_NAME: string
  DESCRIPTION: string
  AUTHOR: string
  BASE_URL: string
  KEYWORDS: string[]
}

export const SEO: ISEO = {
  TITLE: 'Growth Road',
  SITE_NAME: 'Tu Ruta Growth Road',
  DESCRIPTION: 'Tequegi el sabor que nos une',
  AUTHOR: 'Growth Road',
  BASE_URL: 'https://growthroad.es',
  KEYWORDS: ['Growth Road']
}

// SEO by Page
export const SEO_TAGS_COMMON: MetaDefinition[] = [
  { name: 'keywords', content: SEO.KEYWORDS.join(', ') },
  { name: 'author', content: SEO.TITLE },
]

export const SEO_TAGS_HIDDEN_PAGE: MetaDefinition[] = [
  { name: 'robots', content: 'noindex' },
  { name: 'robots', content: 'nofollow' },
]

// Public Pages
export const SEO_TAGS_SIGN_IN_PAGE: MetaDefinition[] = [
  ...SEO_TAGS_COMMON,
  { name: 'description', content: `Iniciar sesión en ${ SEO.TITLE }` },
]

export const SEO_TAGS_SIGN_UP_PAGE: MetaDefinition[] = [
  ...SEO_TAGS_COMMON,
  { name: 'description', content: `Regístrate en ${ SEO.TITLE }` },
]

export const SEO_TAGS_RECOVERY_ACCOUNT_PAGE: MetaDefinition[] = [
  ...SEO_TAGS_COMMON,
  { name: 'description', content: `Recuperar cuenta en ${ SEO.TITLE }` },
]

export const SEO_TAGS_PASSWORD_RESET_PAGE: MetaDefinition[] = [
  ...SEO_TAGS_COMMON,
  { name: 'description', content: `Resetear contraseña en ${ SEO.TITLE }` },
]

export const SEO_TAGS_EMAIL_VERIFICATION_PAGE: MetaDefinition[] = [
  ...SEO_TAGS_COMMON,
  { name: 'description', content: `Verificación de correo en ${ SEO.TITLE }` },
]

export const SEO_TAGS_TERMS_PAGE: MetaDefinition[] = [
  ...SEO_TAGS_COMMON,
  { name: 'description', content: `Terminos y condiciónes en ${ SEO.TITLE }` },
]