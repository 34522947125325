import { UiState } from './../../../store/ui/ui.state';
import { Color } from './../../../models/color.models';
import { SetState, SetThemeColor } from 'src/app/store/ui/ui.actions';
import { UserService } from 'src/app/services/users/users.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { NavigationEnd, Router } from '@angular/router';
import { sleep } from '../../../utils/fakers';
import { MenuOption } from '../../../models/menuOption.models';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnChanges, SimpleChanges, HostListener, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';
import { Store, Select } from '@ngxs/store';
import { UiStateModel } from 'src/app/store/ui/ui.models';
import { ErrorsService } from 'src/app/services/errors.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {
  @ViewChild('slider') slider!: ElementRef
  @ViewChild('drawer__options', { static: false }) drawer__options!: ElementRef
  showDrawer: boolean = true

  sliderMode: boolean = false
  institutionMode: boolean = true

  menuOptions: Array<MenuOption> = [
    {
      link: '/dashboard/home',
      slug: 'inicio',
      title: 'Inicio',
      icon: `/assets/images/icons/icon_home.svg`,
    },
    {
      link: '/dashboard/seasons/',
      slug: 'temporadas',
      title: 'Temporadas',
      icon: `/assets/images/icons/icon_orientacion.svg`,
      dropDownOptions: [
        {
          title: 'Temporada 1',
          subTitle: 'Orientación Vocacional',
          link: '/platform/home/orientation/vocation',
          slug: 'orientation-t1',
        },
        {
          title: 'Temporada 2',
          subTitle: 'Orientación Profesional',
          link: '/platform/home/orientation/profesion',
          slug: 'orientation-t2',
        },
        {
          title: 'Temporada 3',
          subTitle: 'progressExperiencias',
          link: '/platform/home/orientation/experience',
          slug: 'orientation-t3',
        },
      ]
    },
    {
      link: '/dashboard/progress/',
      slug: 'progreso',
      title: 'Mi Progreso',
      icon: `/assets/images/icons/icon_estadisticas.svg`,
    },
    {
      link: '/dashboard/profile',
      slug: 'mi-perfil',
      title: 'Mi Perfil',
      icon: `/assets/images/icons/icon_usuario.svg`,
    },
    // {
    //   link: '/dashboard/reports',
    //   slug: 'reports',
    //   title: 'reports',
    //   icon: `/assets/images/icons/icon_reports.svg`,
    // },
    {
      link: '/dashboard/institution',
      slug: 'institution',
      title: 'Institución',
      icon: `/assets/images/icons/icon_inst-platform.svg`,
      institutionMode: true
    },
  ]
  isSubscribed: boolean = false

  @Select(UiState.mainThemeColor) mainThemeColor$!: Observable<Color>;
  mainThemeColor: Color = '#34AD82' //'#374052' //!GR THEME COLOR

  screenHeight: number = 0
  screenWidth: number = 0

  brandLogo?: string | undefined

  constructor(
    private cookieService: CookieService,
    private errorsService: ErrorsService,
    private authService: AuthService,
    private userService: UserService,
    private renderer: Renderer2,
    private router: Router,
    public store: Store,
    private modalService: NgbModal,
    private elementRef: ElementRef,
  ) {
    this.getScreenSize();
    this.mainThemeColor$.subscribe({
      next: (data) => {
        this.mainThemeColor = data
        this.elementRef.nativeElement.style.setProperty('--main-color', this.mainThemeColor); //set main color on css root
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 991) {
      this.showDrawer = false
    } else {
      this.showDrawer = true
    }
  }

  toggleShow() {
    this.showDrawer = !this.showDrawer
  }

  ngAfterViewInit(): void {
    this.initSlider()
    this.routeSlidablesHandler()
  }

  routeSlidablesHandler(): void {
    let data: NavigationEnd
    const slider = this.slider.nativeElement
    this.router.events.subscribe({
      next: (value) => {
        if (value instanceof NavigationEnd) {
          data = value
          this.renderer.setStyle(slider, 'opacity', 0) //! DEFAULT
          this.isValidRoute(data.url) && this.renderer.setStyle(slider, 'opacity', 1)
        }
      },
      error: (error) => {
        console.error(error)
      },
    });
  }

  isValidRoute(routeUrl: string): boolean {
    let isValid: boolean = true
    if (
      routeUrl !== '/dashboard/home' &&
      routeUrl !== '/dashboard/seasons' &&
      routeUrl !== '/dashboard/progress' &&
      routeUrl !== '/dashboard/profile' &&
      routeUrl !== '/dashboard/vcoach' &&
      !routeUrl.includes('/dashboard/seasons')
    ) {
      isValid = false
    }
    return isValid
  }

  async initSlider(): Promise<void> {
    await sleep(250)

    if (window.innerWidth < 768) {
      this.showDrawer = false
    }

    const slider = this.slider.nativeElement
    const drawer = this.drawer__options.nativeElement
    const activatedOption = drawer.querySelector('.navbar__option.active')

    if (activatedOption) {
      this.renderer.setStyle(slider, 'top', activatedOption.offsetTop + 'px')
    } else {
      this.renderer.setStyle(slider, 'opacity', 0)
    }
  }

  ngOnInit(): void {
    this.getUserData()
    this.brandLogo = this.isImage(localStorage.getItem('brandlogo')!) ? localStorage.getItem('brandlogo')! : undefined
  }

  isImage(url: string) {
    return /jpg|jpeg|png|webp|avif|gif|svg/.test(url);
  }

  changeRoute(event: any) {
    const slider = this.slider.nativeElement
    const offsetTop = event.target.tagName !== 'A' ? event.target.parentElement.offsetTop : event.target.offsetTop
    this.renderer.setStyle(slider, 'top', offsetTop + 'px')
    this.renderer.setStyle(slider, 'opacity', 1)
  }

  // TODO: handle integrations with backend
  logoutHandler() {
    const refreshToken = {
      refresh: this.cookieService.get('gr_refresh')
    }
    this.authService.logout(refreshToken).subscribe({
      complete: () => this.router.navigate(['/']),
      next: () => {
        console.debug('Sesion Cerrada')
        this.cookieService.delete('gr_access', '/')
        this.cookieService.delete('gr_refresh', '/')
      },
      error: ({ error }) => this.errorsService.handleErrors(error),
    })
  }

  private getUserData() {
    let token = this.cookieService.get("gr_access");
    let decode_token = this.authService.getDecodedAccessToken(token);
    this.userService.getUserProfileData(decode_token.user_id).subscribe({
      next: (value) => {
        this.isSubscribed = value.is_subscribed
        console.debug('value', value)
        if (value.user_type !== 'Student') {
          this.menuOptions = this.menuOptions.filter((option) => option.institutionMode)
          this.router.navigate(['/dashboard/institution'])
          this.institutionMode = true
        } else {
          this.menuOptions = this.menuOptions.filter((option) => !option.institutionMode)
          this.institutionMode = false
          this.store.dispatch(new SetThemeColor(this.mainThemeColor));
        }
      },
      error: (error) => this.errorsService.handleErrors(error)
    })
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
  }

}
