<ng-template #createRoomModal let-modal>
  <div class="modal-header">
    <div class="d-flex gap-2 align-items-center">
      <h4 class="modal-title me-3" id="modal-basic-title" [style]="'color:' + mainColor">
        {{student.first_name}} {{student.last_name}}
      </h4>
      <span class="opacity-50">
        {{student.email}}
      </span>
    </div>
    <button type="button" class="btn" (click)="modal.dismiss('Cross click')">Volver</button>
  </div>
  <div class="modal-body">
    <div class="row g-4">
      <!-- Intereses -->
      <div class="col-4">
        <div class="chart-wrapper h-100">
          <h2 class="d-flex justify-content-center align-items-center gap-3" *ngIf="student.vocational_interests">
            <span class="font font-16 font-primary--b text-blue">Intereses Vocacionales</span>
            <div class="info-block">
              <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
              <div class="info-text">
                <p>Existen diferentes ámbitos o campos a los que pertenecen innumerables profesiones o tareas
                  profesionales.
                  Algunos de
                  estos intereses nos llaman más la atención o notamos que parece que se adaptan más a nuestras
                  inquietudes o
                  motivaciones</p>
              </div>
            </div>
          </h2>
          <app-radar-chart *ngIf="student.vocational_interests" [wideMode]="true" [subscribed]="true"
            [chartCategory]="'vocational_interests'" [chartId]="'vocational_interests'">
          </app-radar-chart>
          <div class="not-found-message" *ngIf="!student.vocational_interests">
            <span class="font-primary--b opacity-50">No disponible por el momento</span>
          </div>
        </div>
      </div>
      <!-- Aptitudes -->
      <div class="col-4">
        <div class="chart-wrapper h-100">
          <h2 class="d-flex justify-content-center align-items-center gap-3 mb-0" *ngIf="student.professional_fields">
            <img *ngIf="!true" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
            <span class="font font-16 font-primary--b text-blue">Perfiles Profesionales</span>
            <div class="info-block">
              <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
              <div class="info-text">
                <p>El algoritmo, tras analizar tus respuestas en los diferentes pasos, elaborará tus aptitudes
                  profesionales
                  con el
                  objetivo de que seas consciente cuáles de ellas están más desarrolladas en ti</p>
              </div>
            </div>
          </h2>
          <div class="d-flex justify-content-center  align-items-center">
            <app-doughnut-chart *ngIf="student.professional_fields" [wideMode]="true" [subscribed]="true"
              [chartId]="'profesionalChart'" [classOverlay]="true" [stats]="true"
              [chartCategory]="'professional_fields'">
            </app-doughnut-chart>
            <div class="not-found-message" *ngIf="!student.professional_fields">
              <span class="font-primary--b opacity-50">No disponible por el momento</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Personalidad -->
      <div class="col-4">
        <div class="chart-wrapper h-100">
          <h2 class="d-flex justify-content-center align-items-center gap-3" *ngIf="student.personalities">
            <img *ngIf="!true" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
            <span class="font font-16 font-primary--b text-blue">Personalidad</span>
            <div class="info-block">
              <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
              <div class="info-text">
                <p>Poseer datos acerca de tu personalidad, saber cuáles son tus puntos fuertes y aquello
                  que
                  puedes mejorar es un punto de partida que te colocará en una posición ventajosa frente a otras
                  personas que
                  lo
                  desconocen y por tanto tu probabilidad de acertar en tus elecciones será mayor</p>
              </div>
            </div>
          </h2>
          <app-radar-chart *ngIf="student.personalities" [wideMode]="true" [subscribed]="true"
            [chartCategory]="'personalities'" [chartId]="'personality_chart'">
          </app-radar-chart>
          <div class="not-found-message" *ngIf="!student.personalities">
            <span class="font-primary--b opacity-50">No disponible por el momento</span>
          </div>
        </div>
      </div>
      <!-- Inteligencias -->
      <div class="col-4">
        <div class="chart-wrapper h-100">
          <h2 class="d-flex justify-content-center align-items-center gap-3" *ngIf="student.multiple_intelligences">
            <img *ngIf="!true" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
            <span class="font font-16 font-primary--b text-blue">Inteligencias Múltiples (GARDNER)</span>
            <div class="info-block">
              <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
              <div class="info-text">
                <p>La Teoría de las Inteligencias Múltiples habla de que cada uno de nosotros tiene algunas partes del
                  cerebro
                  más
                  desarrolladas, por tanto, se podrían decir que son más inteligentes que otras personas realizando unas
                  determinadas
                  actividades</p>
              </div>
            </div>
          </h2>
          <app-doughnut-chart *ngIf="student.multiple_intelligences" [wideMode]="true" [subscribed]="true"
            [chartId]="'multipleIntelligences'" [classOverlay]="false" [classOverlayMultiple]="true" [stats]="true"
            [chartHeight]="'300px'" [chartCategory]="'multiple_intelligences'">
          </app-doughnut-chart>
          <div class="not-found-message" *ngIf="!student.multiple_intelligences">
            <span class="font-primary--b opacity-50">No disponible por el momento</span>
          </div>
        </div>

      </div>
      <!-- Emocionales -->
      <div class="col-4">
        <div class="chart-wrapper h-100">
          <h2 class="d-flex justify-content-center align-items-center gap-3" *ngIf="student.intelligences">
            <img *ngIf="!true" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
            <span class="font font-16 font-primary--b text-blue">Inteligencia Emocional</span>
            <div class="info-block">
              <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
              <div class="info-text">
                <p>Nuestro mundo emocional se pone en juego en cada cosa que hacemos o decimos, no hay nada en este
                  mundo que
                  no nos afecte
                  a nivel interior, y saber entender qué está ocurriendo y qué tenemos que hacer con eso es primordial
                  para
                  nuestra propia
                  felicidad</p>
              </div>
            </div>
          </h2>
          <div class="d-flex justify-content-center align-items-center">
            <app-polar-chart *ngIf="student.intelligences" [wideMode]="true" [subscribed]="true"
              [chartCategory]="'intelligences'" [chartId]="'intelligences'">
            </app-polar-chart>
            <div class="not-found-message" *ngIf="!student.intelligences">
              <span class="font-primary--b opacity-50">No disponible por el momento</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Estilos -->
      <div class="col-4">
        <div class="chart-wrapper h-100">
          <div style="max-width: 300px; margin: auto;">
            <h2 class="d-flex justify-content-center align-items-center gap-3" *ngIf="student.learning_styles">
              <img *ngIf="!true" class="img-fluid icon" src="/assets/images/icons/icon_locked_grey.svg" alt="">
              <span class="font font-16 font-primary--b text-blue">Estilos de aprendizaje (Honey y Mumford)</span>
              <div class="info-block">
                <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
                <div class="info-text">
                  <p>Los estilos de aprendizaje son los diferentes modos que tienen las personas de utilizar su
                    inteligencia. Es
                    tan importante lo que aprendes como el modo en el que lo expresas y lo sabes transmitir a los demás.
                  </p>
                </div>
              </div>
            </h2>
            <app-bar-chart *ngIf="student.learning_styles" [chartCategory]="'learning_styles'" [wideMode]="true"
              [subscribed]="true" [chartId]="'learning_styles'">
            </app-bar-chart>

          </div>
          <div class="custom-legend" *ngIf="student.learning_styles">
            <div class="group-one">
              <p><i class="bi bi-circle-fill font font-10 text-green"></i>&nbsp; Activo</p>
              <p><i class="bi bi-circle-fill font font-10 text-red"></i>&nbsp; Reflexivo</p>
            </div>
            <div class="group-two">
              <p><i class="bi bi-circle-fill font font-10 text-orange"></i>&nbsp; Teórico</p>
              <p><i class="bi bi-circle-fill font font-10" style="color: #0058FF;"></i>&nbsp; Pragmático</p>
            </div>
          </div>

          <div class="not-found-message" *ngIf="!student.learning_styles">
            <span class="font-primary--b opacity-50">No disponible por el momento</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<button class="btn bg-transparent outlined font-24 d-flex align-items-center gap-3" (click)="open(createRoomModal)"
  type="button">
  <span>{{student.first_name}}</span>
</button>
