type TUserType = 'Student' | 'Particular' | 'Back_Office_Admin'

export interface User {
  email: string,
  password: string
}
export interface INewUser {
  username?: string
  first_name: string
  last_name: string
  email: string
  phone: string
  password: string
  password2: string
  tax_id: number | string | null
  image_profile: string | null
  is_suscribed: boolean
  user_type: TUserType
  is_archived: boolean
  description: string | null,
  organization: string | null
}
