import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { SEO_TAGS_HIDDEN_PAGE } from 'src/app/config';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  gpdrBlock: boolean = true

  constructor(
    private meta: Meta,
  ) {
    this.meta.addTags(SEO_TAGS_HIDDEN_PAGE)
  }

  ngOnInit(): void { }

  toggleGpdrBlock() {
    this.gpdrBlock = !this.gpdrBlock
  }
}
