import { ToastService } from 'src/app/utils/toast.service';
import { CookieService } from 'ngx-cookie-service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRoom } from 'src/app/models/rooms';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalContent } from '../modal/modal.component';
import { UserService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-create-room',
  templateUrl: './create-room.component.html',
  styleUrls: ['./create-room.component.scss']
})
export class CreateRoomComponent implements OnInit {
  @Output() loadRooms = new EventEmitter();
  @Input('mainColor') mainColor: string = ''
  @Input('institution') institution: any = null

  inProcess: boolean = false
  user_data: any

  room: IRoom = {
    name: '',
    organization: null
  }

  constructor(
    private modalService: NgbModal,
    private roomService: RoomsService,
    private errorsService: ErrorsService,
    private cookieService: CookieService,
    private userService: UserService,
    private authService: AuthService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true,
    })
  }

  openModal(title: string, content: string, type: string = 'error', options?: {}, fnAction?: () => void) {
    const modalRef = this.modalService.open(ModalContent, { centered: true, ...options });
    console.debug()
    if (fnAction !== undefined) {
      modalRef.componentInstance.fnAction = fnAction;
    }
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
  }

  newRoom() {
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    this.inProcess = true
    this.room.organization = this.institution.id
    this.roomService.createRoom(this.room).subscribe({
      next: (data) => {
        this.toastService.closeLoader();
        this.inProcess = false
        this.loadRooms.emit()
        this.modalService.dismissAll()
        this.room.name = ''
      },
      error: (e) => {
        this.toastService.closeLoader();
        this.inProcess = false
        console.debug('error', e)
        this.errorsService.handleErrors(e)
      }
    })
  }
}
