import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { IRoom } from 'src/app/models/rooms';

@Component({
  selector: 'app-show-code',
  templateUrl: './show-code.component.html',
  styleUrls: ['./show-code.component.scss']
})
export class ShowCodeComponent implements OnInit {
  @Input('mainColor') mainColor: string = ''
  @Input('room') room!: IRoom

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg'
    })
  }

  copyOnClipboard() {
    navigator.clipboard.writeText(this.room!.code!);
    this.modalService.dismissAll()
    alert('Código de sala copiado')
  }

}
