import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentBy'
})

export class PercentByPipe implements PipeTransform {

  transform(value: number, ...args: Array<number>): number {
    const length = args[0]
    return (value * 100) / length;
  }

}
