import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ChartService } from 'src/app/services/charts/charts.service';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
Chart.register(...registerables);
@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
  @Input('wideMode') wideMode: boolean = false;
  @Input('subscribed') subscribed: boolean = false;
  @Input('description') description: string = '';
  @Input('chartHeight') chartHeight: any;
  @Input('classOverlay') classOverlay: any;
  @Input('classOverlayMultiple') classOverlayMultiple: any;
  @Input('chartId') chartId: any;
  @Input('chartCategory') chartCategory: any;
  @Input('stats') stats: any;

  public doughnutChartData!: Array<any>;
  public doughnutChartLabels!: string[];
  public colors!: string[];
  chart: any;

  constructor(
    private chartService: ChartService,
    private meta: MetadataService,
  ) { }

  ngOnInit(): void {
    this.doughnutChartData = this.chartService.getChartDataByCategory(this.chartCategory);
    this.doughnutChartLabels = this.meta.getChartLabels(this.chartCategory);
    this.colors = this.meta.getChartColors(this.chartCategory);
    setTimeout(() => {
      this.initChart();
    }, 100);
  }

  initChart() {

    var canvas: any = document.getElementById(this.chartId);
    var ctx2 = canvas.getContext("2d");

    this.chart = new Chart(ctx2, {
      type: 'doughnut',
      data: {
        labels: this.doughnutChartLabels,
        datasets: [
          {
            // data: [5, 4, 5, 4, 5, 4, 5],
            data: this.doughnutChartData,
            backgroundColor: this.colors,
            borderColor: this.colors,
          }
        ]
      },
      options: {
        cutout: this.classOverlay ? 69 : 70,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
            // position: 'right',
            // labels: {
            //   color: '#777',
            //   font: {
            //     size: this.stats ? 12 : 10,
            //   },
            //   padding: this.stats ? 15 : 10,
            //   pointStyle: 'circle',
            //   usePointStyle: true,
            //   boxHeight: 6,
            //   boxWidth: 6
            // }
          },
          tooltip: {
            enabled: true,

          },
        }
      },
    });

  }

  // events
  public chartClicked({ event, active }: { event: any, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: any, active: {}[] }): void {
    console.log(event, active);
  }

}
