import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { ChartService } from 'src/app/services/charts/charts.service';
import { MetadataService } from 'src/app/services/metadata/metadata.services';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  @Input('chartId') chartId: any;
  @Input('chartCategory') chartCategory: any;
  @Input('wideMode') wideMode: boolean = false;
  @Input('subscribed') subscribed: boolean = false;
  @Input('description') description: string = '';

  public dataSets: any;
  public chart: any;
  public labels: any;
  public colors: any;

  constructor(
    private chartService: ChartService,
    private meta: MetadataService,
  ) { }

  ngOnInit(): void {
    this.getChartData();
  }

  getChartData() {
    this.dataSets = this.chartService.getChartDataByCategory(this.chartCategory);
    this.labels = this.meta.getChartLabels(this.chartCategory);
    this.colors = this.meta.getChartColors(this.chartCategory);
    setTimeout(() => {
      this.initChart();
    }, 100);
  }

  initChart() {
    var canvas: any = document.getElementById(this.chartId);
    var ctx2 = canvas.getContext("2d");

    this.chart = new Chart(ctx2, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.dataSets,
            backgroundColor: this.colors,
            barThickness: 15,
            borderRadius: 10,
            minBarLength: 2,
          },
        ]
      },

      options: {
        responsive: true,
        scales: {
          x: {
            display: false
          },
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: false,
          },
        }
      },
    });
  }

}
