import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { ToastService } from '../utils/toast.service'

type TBackErrorsForms = { [key: string]: string[] } | { [key: string]: string }
type TBackErrorsManualHandled = string
type THandleBackErrorsProps = TBackErrorsForms | TBackErrorsManualHandled

@Injectable({ providedIn: 'root' })
export class ErrorsService {
  constructor(private toastService: ToastService, private cookieService: CookieService) { }

  handleErrors(errors: any): void { // TODO Refactor
    const msgs = errors?.error ? Object.values(errors.error) : Object.values(errors)
    let errorsMsgs: string[] = []

    console.log('Error Messages', msgs)

    if (Array.isArray(msgs[0])) {

      msgs.forEach((msg: any) => {
        if (msg[0] instanceof Object) { // if item is a object with message content
          return msg[0]['message']
        }

        const contentMsg = `<li>${msg[0]}</li>`
        errorsMsgs.push(contentMsg)
      })

    } else {

      const contentMsg = `<li>${msgs[0] === 'El token dado no es valido para ningun tipo de token'
        ? 'Tu sesión ha expirado'
        : msgs[0]
        }</li>`
      errorsMsgs.push(contentMsg)

    }

    const errorMsg = errorsMsgs.join('')

    const htmlErrorsContent = `
      <ul class="list-dots">
        ${errorMsg}
      </ul>
    `

    if (errorMsg.includes('Tu sesión ha expirado')) {
      this.cookieService.delete('gr_access')

      this.toastService.showNotificationModal(
        '¡Algo ha ocurrido!',
        htmlErrorsContent,
        'notification',
        'Iniciar Sesión',
        '/signin'
      )

      return
    }

    this.toastService.showNotificationModal(
      '¡Algo ha ocurrido!',
      htmlErrorsContent.replace('><<', '>Ha ocurrido un error, si persiste por favor ponerse en contacto con el administrador,<'),
      'notification',
      'Volver a intentar'
    )
  }
}
