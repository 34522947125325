import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SEO } from 'src/app/config';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor(
    private title: Title,
  ) {
    this.title.setTitle(`Pagina No Encontrada | ${ SEO.TITLE }`)
  }

  ngOnInit(): void {
  }

}
