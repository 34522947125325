import { Color } from './../../../../models/color.models';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-icon',
  templateUrl: './arrow-icon.component.html',
  styleUrls: ['./arrow-icon.component.scss']
})
export class ArrowIconComponent implements OnInit {
  @Input('flipVertical') flipVertical: boolean = false
  @Input('active') active: boolean = false
  @Input('mainColor') mainColor: string = '#fff'

  constructor() { }

  ngOnInit(): void {
  }

}
