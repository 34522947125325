export const environment = {
  name: 'DEV',
  production: false,
  apiUrl: 'https://back.dev.growthroad.es',
  boUrl: 'https://office.dev.growthroad.es',
  appUrl: 'https://dev.growthroad.es',
  recaptcha: {
    siteKey: '6LdMtngaAAAAADm3dthutPIO4UBGiR6aP4nqV9t',
  },
};
