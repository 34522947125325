import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxsModule } from '@ngxs/store';
import { NgModule } from '@angular/core';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_LANGUAGE, RecaptchaFormsModule } from "ng-recaptcha";

import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';
import { MetadataService } from './services/metadata/metadata.services';
import { PaymentService } from './services/payments/payment.service';
import { CommonProvider } from './services/common/common.provider';
import { UserService } from './services/users/users.service';
import { environment } from './../environments/environment';
import { ThreeState } from './store/three/three.state';
import { FormService } from './services/form.service';
import { ItemState } from './store/item.state';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { NotFoundPageComponent } from './shared/pages/not-found-page/not-found-page.component';
import { ErrorLayoutComponent } from './shared/layouts/error-layout/error-layout.component';
import { AppComponent } from './app.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { UiState } from './store/ui/ui.state';


@NgModule({
  declarations: [
    AppComponent,
    ErrorLayoutComponent,
    NotFoundPageComponent,
    MaintenancePageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // RecaptchaV3Module,
    // RecaptchaFormsModule,
    NgScrollbarModule,
    NgxsModule.forRoot([ItemState, ThreeState, UiState], {
      developmentMode: !environment.production,
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    SharedModule,
  ],
  providers: [
    CookieService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    // {
    //   provide: RECAPTCHA_LANGUAGE,
    //   useValue: "es",
    // },
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: '6LdMtngaAAAAADm3dthutPIO4UBGiR6aP4nqV9t'
    // },
    NgbActiveModal,
    UserService,
    MetadataService,
    ToastrService,
    FormService,
    CommonProvider,
    PaymentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
