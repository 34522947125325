import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { IRoom } from 'src/app/models/rooms';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getRooms(): Observable<any> {
    let url = environment.apiUrl + '/organizations/rooms/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  createRoom(room: IRoom): Observable<any> {
    let url = environment.apiUrl + '/organizations/rooms/';
    return this.httpClient.post(url, room).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getInstitution(institutionId: string): Observable<any> {
    let url = environment.apiUrl + '/organizations/' + institutionId + '/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  requestJoinToRoom(roomCode: { code: string }): Observable<any> {
    let url = environment.apiUrl + '/organizations/rooms/request_to_join/';
    return this.httpClient.post(url, roomCode).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  answerRequestToJoin(participantId: string, answer: { answer: boolean }): Observable<any> {
    let url = environment.apiUrl + '/organizations/answer-request/' + participantId;
    return this.httpClient.post(url, answer).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getPetitions(roomId: string): Observable<any> {
    let url = environment.apiUrl + '/organizations/participant-requests/' + roomId;
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  editRoom(room: IRoom): Observable<any> {
    let url = environment.apiUrl + '/organizations/rooms/' + room.id! + '/';
    return this.httpClient.put(url, room).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  deleteRoom(roomId: string): Observable<any> {
    let url = environment.apiUrl + '/organizations/rooms/' + roomId + '/';
    return this.httpClient.delete(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  dropStudentFromRoom(studentId: string) {
    // organizations/remove-user/:id
    let url = environment.apiUrl + '/organizations/remove-users/' + studentId;
    return this.httpClient.delete(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  moveStudent(roomData: any, studentId: string) {
    let url = environment.apiUrl + '/organizations/move-users/' + studentId;
    return this.httpClient.put(url, roomData).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getInstitutions(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/organizations/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
