import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartService } from 'src/app/services/charts/charts.service';
import { ChartType } from 'chart.js';
import { Chart, registerables } from 'chart.js';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
Chart.register(...registerables);

@Component({
  selector: 'app-polar-chart',
  templateUrl: './polar-chart.component.html',
  styleUrls: ['./polar-chart.component.scss']
})
export class PolarChartComponent implements OnInit {

  @Input('chartId') chartId: any;
  @Input('chartCategory') chartCategory: any;
  @Input('subscribed') subscribed: any;
  @Input('wideMode') wideMode: boolean = false;
  @Input('description') description: string = '';

  public dataSets: any;
  public chart: any;
  public labels: any;
  public colors: any;

  constructor(
    private chartService: ChartService,
    private meta: MetadataService,
  ) { }

  ngOnInit(): void {
    this.getChartData();
  }

  getChartData() {
    this.dataSets = this.chartService.getChartDataByCategory(this.chartCategory);
    this.labels = this.meta.getChartLabels(this.chartCategory);
    this.colors = this.meta.getChartColors(this.chartCategory);
    setTimeout(() => {
      this.initChart();
    }, 100);
  }

  initChart() {
    var canvas: any = document.getElementById(this.chartId);
    var ctx2 = canvas.getContext("2d");

    this.chart = new Chart(ctx2, {
      type: 'polarArea',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.dataSets,
            backgroundColor: this.colors.background,
            borderColor: this.colors.borders
          }
        ]
      },

      options: {
        responsive: true,
        // maintainAspectRatio: true,
        scales: {
          r: {
            ticks: {
              display: false
            },
            angleLines: {
              color: '#aaa'
            },
            grid: {
              color: '#ccc',
            },
            pointLabels: {
              color: '#666',
              font: {
                size: 12,
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'right',
            labels: {
              color: '#777',
              font: {
                size: 12,
              },
              padding: 15,
              pointStyle: 'circle',
              usePointStyle: true,
              boxHeight: 6,
              boxWidth: 6
            }
          },
        }
      },
    });
  }

}
