<div class="stepper">
  <div class="header">
    <h1 class="font-header">OnBoarding</h1>
  </div>
  <div class="steps">
    <div class="step" [class]="'step__'+i" [class.active]="currentStep === i" *ngFor="let step of steps; index as i">
      <div class="step__content" *ngIf="currentStep === i">
        <div class="video-container" *ngIf="step.type === 'video';else imageStep">
          <app-video [options]="{
              fluid: true,
              aspectRatio: '16:9',
              autoplay: false,
              controls: true,
              sources: [{
                src: 'https://grapi.growthroad.es:5555/noauth/video?vid=intro_voc&ext=mp4',
                type: 'video/mp4' }]}">
          </app-video>
        </div>
        <ng-template #imageStep>
          <img class="img-fluid" src="{{step.src}}" alt="">
        </ng-template>
      </div>
    </div>
  </div>
  <div class="footer">
    <button class="btn text-white bg-red" (click)="skipSteps()">Saltar</button>
    <button class="btn text-white bg-red" (click)="prevStep()">Atras</button>
    <button class="btn text-white bg-red" (click)="nextStep()">
      <span class="text-white" *ngIf="currentStep < maxSteps;else goToPlatform">Siguiente</span>
      <ng-template #goToPlatform>
        <span class="text-white">Ir a la plataforma</span>
      </ng-template>
    </button>
  </div>
</div>
