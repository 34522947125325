<ng-template #createRoomModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Nueva sala
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-12 d-flex flex-column">
          <label for="title">Nombre</label>
          <input class="input" type="text" id="title" [(ngModel)]="room.name" name="title"
            placeholder="Nombre de la sala">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark bg-transparent text-red" (click)="modal.close()">Volver</button>
    <button type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess" (click)="newRoom()">
      <span class="text-white" *ngIf="!inProcess">
        Guardar
      </span>
      <app-loading *ngIf="inProcess" color="#fff"></app-loading>
    </button>
  </div>
</ng-template>
<div class="row justify-content-end mt-4">
  <div class="col-auto">
    <button class="btn bg-transparent outlined font-24 d-flex align-items-center gap-3"
      [style]="'border-color: ' + mainColor" (click)="open(createRoomModal)" type="button">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14.104" height="14.104" viewBox="0 0 14.104 14.104">
          <g id="Icon_ionic-ios-add-circle-outline" data-name="Icon ionic-ios-add-circle-outline"
            transform="translate(0 0)">
            <path id="Trazado_1307" data-name="Trazado 1307"
              d="M17.191,13.58H14.665V11.054a.542.542,0,0,0-1.085,0V13.58H11.054a.52.52,0,0,0-.542.542.525.525,0,0,0,.542.542H13.58v2.526a.525.525,0,0,0,.542.542.54.54,0,0,0,.542-.542V14.665h2.526a.542.542,0,1,0,0-1.085Z"
              transform="translate(-7.071 -7.071)" [attr.fill]="mainColor" />
            <path id="Trazado_1308" data-name="Trazado 1308"
              d="M10.427,4.324A6.1,6.1,0,1,1,6.111,6.111a6.062,6.062,0,0,1,4.316-1.787m0-.949a7.052,7.052,0,1,0,7.052,7.052,7.051,7.051,0,0,0-7.052-7.052Z"
              transform="translate(-3.375 -3.375)" [attr.fill]="mainColor" />
          </g>
        </svg>

      </span>
      <span [style]="'color:' + mainColor">Añadir sala</span>
    </button>
  </div>
</div>