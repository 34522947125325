import { Color } from './../../../models/color.models';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartService } from 'src/app/services/charts/charts.service';

@Component({
  selector: 'app-detail-stadistics',
  templateUrl: './detail-stadistics.component.html',
  styleUrls: ['./detail-stadistics.component.scss'],
})
export class DetailStadisticsComponent implements OnInit {
  @Input('student') student: any;
  @Input('mainColor') mainColor: Color | string = '#34AD82';

  constructor(private modalService: NgbModal, private chartsService: ChartService) { }

  ngOnInit(): void { }

  open(content: any) {
    const { intelligences,
      interest_options,
      learning_styles,
      multiple_intelligences,
      personalities,
      professional_fields,
      vocational_interests } = this.student

    //?? En un futuro 'label' estara en todos, recuerda borrarlos
    delete vocational_interests.label
    delete professional_fields.label

    const chartData = {
      intelligences,
      interest_options,
      learning_styles,
      multiple_intelligences,
      personalities,
      professional_fields,
      vocational_interests,
    }

    this.chartsService.setChartsData(chartData)
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      windowClass: 'custom-modal'
    });
  }
}
