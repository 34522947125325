<ng-template #showCodeRoomModal let-modal>
  <div class="modal-body">
    <h2 class="font-42 font-secondary--eb text-center pt-5 px-5 pb-2" [style]="'color:'+mainColor">
      <!-- 1026 9856 -->
      {{room.code}}
    </h2>
  </div>
  <div class="modal-footer-custom">
    <div class="options-wrapper d-flex align-items-center justify-content-between pt-0 pb-4 px-4">
      <p class="font-primary--b">
        {{room.name}}
      </p>
      <div class="toolbar d-flex align-items-center gap-3">
        <button class="btn d-flex align-items-center gap-2" (click)="copyOnClipboard()">
          <span>
            <img src="/assets/images/icons/icon_copy.svg" alt="">
          </span>
          <span>Copiar código de sala</span>
        </button>
        <button class="btn" (click)="modal.dismiss('Cross click')">
          <span>
            <img src="/assets/images/icons/icon_fullscreen-exit.svg" alt="">
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<button class="btn" (click)="open(showCodeRoomModal)">
  <svg xmlns="http://www.w3.org/2000/svg" width="15.806" height="15.806" viewBox="0 0 15.806 15.806">
    <path id="Icon_material-zoom-out-map" data-name="Icon material-zoom-out-map"
      d="M15.037,4.5l2.02,2.02L14.519,9.04l1.247,1.247,2.52-2.538,2.02,2.02V4.5ZM4.5,9.769l2.02-2.02,2.52,2.538L10.287,9.04,7.749,6.52,9.769,4.5H4.5ZM9.769,20.306l-2.02-2.02,2.538-2.52L9.04,14.519,6.52,17.057,4.5,15.037v5.269Zm10.537-5.269-2.02,2.02-2.52-2.538-1.247,1.247,2.538,2.52-2.02,2.02h5.269Z"
      transform="translate(-4.5 -4.5)" [attr.fill]="mainColor" />
  </svg>

</button>