<main class="maintenance-page page" style="background-image: url(/assets/images/patterns/pattern_membership.png);">
  <app-card padding="50px 0  150px 0" borderRadius="10px" boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 6px" width="991px">
    <article card__content class="row text-center">
      <img class="brand-image" src="/assets/images/icons/icon-brand-optimized.png" alt="">
      <h1 class="font-primary--b font-24 mb-3">En estos momentos estamos en mantenimiento...</h1>
      <p class="font-18 opacity-50">
        Estamos realizando actualizaciones para mejorar nuestra plataforma.
      </p>
      <p class="font-18 opacity-50">
        Ten paciencia, regresaremos muy pronto
      </p>
    </article>
  </app-card>
</main>