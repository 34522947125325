import { Color } from './../../models/color.models';
export class SetState {
  static readonly type = '[Drawer] show or hide drawer';
  constructor(public payload: boolean) {
    console.debug('payload', payload)
  }
}
export class SetThemeColor {
  static readonly type = '[Theme] set new theme color';
  constructor(public payload: Color) {
    console.debug('payload', payload)
  }
}
