import { environment } from './../environments/environment';
import { User, INewUser } from './models/user.models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { CanActivate, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    public router: Router) { }

  canActivate(): boolean {
    let canActivate = true

    if (!this.isAuthenticated()) {
      this.router.navigate(['/signin']);
      canActivate = false
    }

    return canActivate;
  }

  public isAuthenticated(): boolean {
    const token = this.cookieService.get('gr_access');

    let isAuthenticated = false

    if (location.hash.split('=').length > 1) {
      const firstPhase = location.hash.split('=')

      this.cookieService.set('gr_refresh', firstPhase[1].split('&')[0]);
      this.cookieService.set('gr_access', firstPhase[2].split('&')[0]);

      isAuthenticated = true
    }

    if (token) {
      isAuthenticated = true
    }
    return isAuthenticated
  }

  signIn(user: any): Observable<any> {
    const url = environment.apiUrl + '/authentication/login'
    return this.httpClient.post(url, user).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  logout(refreshToken: { refresh: string }): Observable<any> {
    const url = environment.apiUrl + '/authentication/logout'
    const options = {
      headers: {
        Authorization: 'Bearer ' + this.cookieService.get('gr_access')
      }
    }
    return this.httpClient.post(url, refreshToken, options).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  signUp(user: INewUser): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/authentication/sign-up`, user,).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  completeProfile(user: any, meta: any): Observable<any> {
    const url = `${environment.apiUrl}/authentication/complete-profile/${meta.token}/${meta.subs}/${meta.userId}`

    return this.httpClient.put(url, user).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getUserById(userId: string) {
    const url = `${environment.apiUrl}/users/${userId}/external`

    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  verifyEmail(token: string, emailIdB64: string, userIdB64: string) {
    const URL = `${environment.apiUrl}/authentication/verification-email/${token}/${emailIdB64}/${userIdB64}`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.put(URL, {}).pipe(handleErrors)
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getMe(userId: number): Observable<any> {
    const URL = `${environment.apiUrl}/users/${userId}/`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.get(URL).pipe(handleErrors)
  }

  async resetPassword(token: string, password: string): Promise<{ data: any, error: boolean }> { // TODO handle errors
    const URL = environment.apiUrl + '/authentication/reset-password/confirm/';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, password })
    }

    const res = await fetch(URL, options)
    const data = await res.json()

    return { data, error: !res.ok }
  }

  async sendLinkToResetPassword(email: string): Promise<{ data: any, error: boolean }> { // TODO handle errors
    const URL = environment.apiUrl + '/authentication/reset-password/';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    }

    const res = await fetch(URL, options)
    const data = await res.json()

    return { data, error: !res.ok }
  }

  testingLTI(): Observable<any> {
    const body = new URLSearchParams();
    body.set('lti_message_type', 'basic-lti-launch-request')
    body.set('lti_version', 'LTI-1p0')
    body.set('resource_link_id', '1')
    body.set('lis_person_name_given', 'Jacobo')
    body.set('lis_person_name_family', 'Martínez')
    body.set('lis_person_contact_email_primary', 'jacobo.amn89@gmail.com')
    body.set('user_id', '9999')

    body.set('oauth_consumer_key', 'M36MKFHS8Q9RSG3EH71G26XJIMSZW7G0')
    body.set('oauth_nonce', 'PLTT99eToTR')
    body.set('oauth_signature', 'RmH8H6t66EKJfmCPwjro5S64Bk8=')
    body.set('oauth_signature_method', 'HMAC-SHA1')
    body.set('oauth_timestamp', '1666149977')
    body.set('oauth_version', '1.0')

    let options = {
      // cookie: 'csrftoken=inFU9OKVIhP8wASvPHtFo5cOZfBPlzLNqc85spSUqBL43ugcEPhhSlJXlXWhIM3j; sessionid=hw2gt4tiho691t46wne3fjnyxjihjyo0',
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
      // .set('cookie', 'csrftoken=inFU9OKVIhP8wASvPHtFo5cOZfBPlzLNqc85spSUqBL43ugcEPhhSlJXlXWhIM3j;sessionid=hw2gt4tiho691t46wne3fjnyxjihjyo0')
      // .set('oauth_consumer_key', 'M36MKFHS8Q9RSG3EH71G26XJIMSZW7G0')
      // .set('oauth_nonce', 'VamhUPMSXi9j6UHRtqubVkHdTU0rNRs5')
      // .set('oauth_signature', 'cbcDxk9JT6%2F9M4saJekIAafujrY%3D')
      // .set('oauth_signature_method', 'HMAC-SHA1')
      // .set('oauth_timestamp', '1666147442')
      // .set('oauth_version', '1.0')
    }


    return this.httpClient.post(`${environment.apiUrl}/lti/`, body.toString(), options).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
