import { Injectable } from '@angular/core';
import { FormControl, Validators, FormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  getUserProfileForm(profile_data: any) {
    let form = new FormGroup({
      description: new FormControl(profile_data.description ? profile_data.description : ''),
      email: new FormControl(profile_data.email, Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      ])),
      first_name: new FormControl(profile_data.first_name ? profile_data.first_name : null),
      image_profile: new FormControl(profile_data.image_profile ? profile_data.image_profile : ''),
      last_name: new FormControl(profile_data.last_name ? profile_data.last_name : null),
      phone: new FormControl(profile_data.phone ? profile_data.phone : null),
      tax_id: new FormControl(profile_data.tax_id ? profile_data.tax_id : null),
    });
    return form;
  }

  getChangeEmailForm() {
    let form = new FormGroup({
      new_email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      ])),
      confirm_new_email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      ])),
    }, { validators: this.identityRevealedValidator });
    return form;
  }

  identityRevealedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const new_email = control.get('new_email')?.value;
    const confirm_new_email = control.get('confirm_new_email')?.value;
    return new_email === confirm_new_email ? null : { notSame: false }
  };

  getNewPaymentMethodForm() {
    let form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(16)]),
      cvc: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      exp_month: new FormControl('', [Validators.required]),
      exp_year: new FormControl('', [Validators.required]),
      brand: new FormControl(''),
    })

    return form
  }

  validAmexCode: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let brand = control.get('brand')?.value;
    let cvc = control.get('cvc')?.value;
    return brand == 'amex' && cvc.length !== 4 ? { misMatchValidationCode: true } : null
  }

  validCode: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let brand = control.get('brand')?.value;
    let cvc = control.get('cvc')?.value;
    return brand !== 'amex' && cvc.length !== 3 ? { misMatchValidationCode: true } : null
  }
}

