import { CircleProgressComponent, NgCircleProgressModule } from 'ng-circle-progress';
import { NgbModule, NgbProgressbar, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CarouselComponent, CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { DrawerComponent } from './components/drawer/drawer.component';
import { OnboardingStepperComponent } from './components/onboarding-stepper/onboarding-stepper.component';
import { VideoComponent } from '../questionary/components/video/video.component';
import { CustomDatePipe, CustomDatePipeShort } from './pipes/formatDate.pipe';
import { PercentByPipe } from './pipes/percent-by.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CardComponent } from './components/card/card.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { PolarChartComponent } from './components/charts/polar-chart/polar-chart/polar-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart/radar-chart.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalComponent, ModalContent } from './components/modal/modal.component';
import { PanelComponent } from './components/panel/panel.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { RadioComponent } from './components/radio/radio.component';
import { ProgressChartsComponent } from './components/charts/progress-charts/progress-charts.component';
import { EmptyPageComponent } from './layouts/empty-page/empty-page.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MembershipCardComponent } from '../user/components/membership-card/membership-card.component';
import { GraphComponent } from './components/charts/graph/graph.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { GpdrComponent } from './components/gpdr/gpdr.component';
import { CreateRoomComponent } from './components/create-room/create-room.component';
import { ShowRoomComponent } from './components/show-room/show-room.component';
import { EditRoomComponent } from './components/edit-room/edit-room.component';
import { ShowCodeComponent } from './components/show-code/show-code.component';
import { FilterStudentsPipe } from './pipes/filter-students.pipe';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { ArrowIconComponent } from './components/icons/arrow-icon/arrow-icon.component';
import { DetailStadisticsComponent } from './components/detail-stadistics/detail-stadistics.component';

@NgModule({
  declarations: [
    ModalComponent,
    ModalContent,
    OnboardingStepperComponent,
    LoadingComponent,
    CardComponent,
    VideoComponent,
    PanelComponent,
    DrawerComponent,
    DashboardLayoutComponent,
    RadioComponent,
    MembershipCardComponent,
    EmptyPageComponent,
    LoaderComponent,
    TermsPageComponent,
    ToggleSwitchComponent,

    // Chart Components
    RadarChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    PolarChartComponent,
    ProgressChartsComponent,
    GraphComponent,

    // pipes
    SafeHtmlPipe,
    PercentByPipe,
    CustomDatePipe,
    CustomDatePipeShort,
    GpdrComponent,

    //Components
    CreateRoomComponent,
    ShowRoomComponent,
    EditRoomComponent,
    ShowCodeComponent,
    FilterStudentsPipe,
    EditUserComponent,
    ArrowIconComponent,
    DetailStadisticsComponent,
  ],
  exports: [
    DetailStadisticsComponent,
    ArrowIconComponent,
    ModalComponent,
    ModalContent,
    OnboardingStepperComponent,
    LoadingComponent,
    CardComponent,
    VideoComponent,
    PanelComponent,
    CarouselComponent,
    DrawerComponent,
    DashboardLayoutComponent,
    CarouselModule,
    RadioComponent,
    MembershipCardComponent,
    EmptyPageComponent,
    LoaderComponent,
    ToggleSwitchComponent,
    GpdrComponent,
    CreateRoomComponent,
    ShowRoomComponent,
    EditRoomComponent,
    ShowCodeComponent,
    EditUserComponent,

    // Chart
    CircleProgressComponent,
    DoughnutChartComponent,
    PolarChartComponent,
    RadarChartComponent,
    BarChartComponent,
    ProgressChartsComponent,
    GraphComponent,

    // Bootstrap
    NgbProgressbar,
    NgbAlert,


    // pipes
    SafeHtmlPipe,
    PercentByPipe,
    CustomDatePipe,
    CustomDatePipeShort,
    FilterStudentsPipe,

    FormsModule,
    ReactiveFormsModule,
  ],
  imports: [
    // Chart
    NgCircleProgressModule.forRoot({}),
    CommonModule,
    CarouselModule,
    RouterModule,
    NgbModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  entryComponents: [
    LoaderComponent
  ],
})
export class SharedModule { }
