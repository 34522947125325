<div class="panel carouselMode" [class.flipMode]="!chapter._enabled" *ngIf="isCarousel">
  <div class="panel-body"
    [style]="{background: chapter._enabled ? 'transparent linear-gradient(140deg, '+chapter.background_color+' 0%, '+chapter.gradient_color+' 100%) 0% 0%' : '#a2a2a2'}">
    <div class="panel-front" [ngStyle]="{backgroundImage: 'url('+chapter.image+')' }">
      <!-- HEAD -->
      <div class="header">
        <h2 class="title" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">Capítulo {{chapterNumber+1}}
        </h2>
        <h3 class="sub-title" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">{{chapter.name}}</h3>
      </div>
      <!-- PROGRESS PERCENT -->
      <a class="progress-wrapper" [routerLink]="chapter._enabled ? '/dashboard/seasons/1/chapters/'+ chapter.id : null">
        <circle-progress *ngIf="chapter._enabled ;else locked" [outerStrokeGradient]="false"
          [outerStrokeColor]="chapter._enabled ? chapter.font_color : '#fff'"
          [percent]="chapter.progress.progress_percentage" [unitsColor]="chapter._enabled ? chapter.font_color : '#fff'"
          [titleColor]="chapter._enabled ? chapter.font_color : '#fff'" [options]="circleProgressOption">
        </circle-progress>
        <ng-template #locked>
          <img class="locked-icon" src="/assets/images/icons/icon_locked.svg" alt="">
        </ng-template>
      </a>
      <!-- THEME PROGRESS -->
      <p class="themes-progress" *ngIf="chapter._enabled"
        [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">
        <span [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}"
          *ngIf="chapter.progress.children_completed_1">{{ chapter.progress.children_completed_1 }}</span>
        <span [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}"
          *ngIf="!chapter.progress.children_completed_1">0</span>/{{chapter.progress.children_total_1}} temas
      </p>
    </div>

    <div class="panel-back" *ngIf="!chapter._enabled" [ngStyle]="{backgroundImage: 'url('+chapter.image+')' }">
      <!-- HEAD -->
      <div class="header">
        <img src="/assets/images/icons/icon_locked.svg" class="icon" alt="">
        <h2 class="title" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">Capítulo
          {{chapterNumber+1}}: {{chapter.name}}
        </h2>
      </div>
      <p class="description" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">
        {{chapter.description}}
      </p>
      <button *ngIf="!userSubscribed" [routerLink]="'/dashboard/membership'"
        class="btn bg-green text-white font-primary--b">
        Desbloquear Capítulos
      </button>
    </div>
  </div>
</div>

<div class="panel-wrapper" *ngIf="!isCarousel">
  <div class="panel panel-chapter" [class.carousel-panel]="isCarousel"
    [style]="{background: chapter._enabled ? 'transparent linear-gradient(140deg, '+chapter.background_color+' 0%, '+chapter.gradient_color+' 100%) 0% 0%' : '#a2a2a2'}">

    <div class="content-wrapper" [ngStyle]="{backgroundImage: 'url('+chapter.image+')' }">
      <div class="header">
        <h2 class="title" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">Capítulo {{chapterNumber+1}}
        </h2>
        <h3 class="sub-title" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">{{chapter.name}}</h3>
      </div>

      <a class="progress-wrapper" [routerLink]="chapter._enabled ? '/dashboard/seasons/1/chapters/'+ chapter.id : null">
        <circle-progress *ngIf="chapter._enabled ;else locked" [outerStrokeGradient]="false"
          [outerStrokeColor]="chapter._enabled ? chapter.font_color : '#fff'"
          [percent]="chapter.progress.progress_percentage" [unitsColor]="chapter._enabled ? chapter.font_color : '#fff'"
          [titleColor]="chapter._enabled ? chapter.font_color : '#fff'" [options]="circleProgressOption">
        </circle-progress>
        <ng-template #locked>
          <img class="locked-icon" src="/assets/images/icons/icon_locked.svg" alt="">
        </ng-template>
      </a>

      <p class="themes-progress" *ngIf="chapter._enabled"
        [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">
        <span [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}"
          *ngIf="chapter.progress.children_completed_1">{{ chapter.progress.children_completed_1 }}</span>
        <span [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}"
          *ngIf="!chapter.progress.children_completed_1">0</span>/{{chapter.progress.children_total_1}} temas
      </p>
    </div>
  </div>

</div>
