import {
  IncrementValue,
  DecrementValue,
  ResetValue,
  SetValue,
} from './item.actions';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ItemModel } from './item.model';

const SIMPLE_VALUE_STATE_TOKEN = new StateToken<ItemModel>(
  'simpleValue'
);

@State<ItemModel>({
  name: SIMPLE_VALUE_STATE_TOKEN,
  defaults: {
    value: 0,
  },
})
@Injectable()
export class ItemState {
  @Action(IncrementValue)
  incrementValue(ctx: StateContext<ItemModel>) {
    const state = ctx.getState();
    let stateValue = state.value;
    stateValue++;
    ctx.setState({
      ...state,
      value: stateValue,
    });
  }

  @Action(DecrementValue)
  decrementValue(ctx: StateContext<ItemModel>) {
    const state = ctx.getState();
    let stateValue = state.value;
    stateValue--;
    ctx.setState({
      ...state,
      value: stateValue,
    });
  }

  @Action(ResetValue)
  resetValue(ctx: StateContext<ItemModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      value: 0,
    });
  }

  @Action(SetValue)
  setValue(ctx: StateContext<ItemModel>, action: SetValue) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      value: action.payload,
    });
  }

  @Selector()
  static value(state: ItemModel) {
    return state.value;
  }
}
