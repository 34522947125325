import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Imembership } from 'src/app/models/membership';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  //Subscriptions
  getSubscriptions() {
    let url = environment.apiUrl + '/subscriptions/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  //Products
  getPaymentsProducts(id?: any) {
    let url = environment.apiUrl + '/product/';
    if (id) url = url + id + '/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  subscribeProduct(product_id: any, payment_method_id: any, coupon_id?: any) {
    let url = environment.apiUrl + '/subscriptions/';
    let data = {
      product: product_id,
      st_credit_card: payment_method_id,
      coupon: coupon_id
    }
    return this.httpClient.post(url, data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }


  //Payment methods
  getPaymentHistory() {
    let url = environment.apiUrl + '/payment/list-payments/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getUserPaymentMethods() {
    let url = environment.apiUrl + '/payment/stripe-credit-card/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  createPaymentMethod(data: any) {
    let url = environment.apiUrl + '/payment/stripe-credit-card/';
    return this.httpClient.post(url, data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  deletePaymentMethod(card_id: any) {
    let url = environment.apiUrl + '/payment/stripe-credit-card/' + card_id + '/';
    return this.httpClient.delete(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  setDafaultPaymentMethod(card_id: any) {
    let url = environment.apiUrl + '/payment/stripe-credit-card/__card_id__/set_default/';
    url = url.replace('__card_id__', card_id);
    return this.httpClient.post(url, '').pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  //Coupons
  getAllCoupons() {
    let url = environment.apiUrl + '/coupons/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  //?? Membership
  giftMembership(payload: Imembership) {
    let url = environment.apiUrl + '/external-subscriptions/';
    return this.httpClient.post(url, payload).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )

  }

}
