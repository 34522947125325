import { Meta, Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { SEO, SEO_TAGS_TERMS_PAGE } from 'src/app/config';
@Component({
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent implements OnInit {

  constructor(
    private location: Location,
    private title: Title,
    private meta: Meta
    ) {
    this.title.setTitle(`Términos y Condiciones | ${ SEO.TITLE }`)
    this.meta.addTags(SEO_TAGS_TERMS_PAGE)
  }

  ngOnInit(): void {
  }

  backRoute(): void {
    this.location.back()
  }
}
