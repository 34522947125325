import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStudents'
})
export class FilterStudentsPipe implements PipeTransform {

  transform(items: any[], searchText: any, parameterSelected: any): any[] {

    let filteredItems = []
    if (!items) {
      return [];
    }

    if (!searchText && !parameterSelected) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    if (searchText) {
      filteredItems = items!.filter((participant) => {
        return (
          participant.first_name.toLowerCase().includes(searchText) ||
          participant.last_name.toLowerCase().includes(searchText) ||
          participant.email.toLowerCase().includes(searchText)
        );
      });
    }

    if (parameterSelected) {
      filteredItems = items!.filter((participant) => {
        return Object.values(participant.heaviest_params).find(o => o == parameterSelected);
      });
    }
    if (searchText && parameterSelected) {
      filteredItems = items!.filter((participant) => {
        return Object.values(participant.heaviest_params).find(o => o == parameterSelected);
      });

      filteredItems = filteredItems!.filter((participant) => {
        return (
          participant.first_name.toLowerCase().includes(searchText) ||
          participant.last_name.toLowerCase().includes(searchText) ||
          participant.email.toLowerCase().includes(searchText)
        );
      });
    }
    return filteredItems
  }

}
