<div class="graph-component" [class.unsubscribed]="!subscribed" [class.wideMode]="wideMode">
  <canvas #{{chartId}} id="{{ chartId }}"></canvas>
  <div class="unsubscribed-card" *ngIf="!subscribed">
    <p class="description">
      {{description}}
    </p>
    <div class="text-end">
      <a class="mt-2 ms-auto d-block text-red" routerLink="/dashboard/membership">Desbloquear Estadísticas</a>
    </div>
  </div>
</div>
