import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-charts',
  templateUrl: './progress-charts.component.html',
  styleUrls: ['./progress-charts.component.scss']
})
export class ProgressChartsComponent implements OnInit {
  @Input('is_subscribed') is_subscribed: any;

  constructor() { }

  ngOnInit(): void {
  }

}
