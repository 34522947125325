<ng-template #editRoomModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Editar sala
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-12 d-flex flex-column">
          <label for="title">Nombre</label>
          <input class="input" type="text" id="title" [(ngModel)]="room.name" name="title"
            placeholder="Nombre de la sala">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark bg-transparent text-red" (click)="modal.close()">Volver</button>
    <button type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess" (click)="editRoom()">
      <span class="text-white" *ngIf="!inProcess">
        Actualizar
      </span>
      <app-loading *ngIf="inProcess" color="#fff"></app-loading>
    </button>
  </div>
</ng-template>
<button class="btn d-flex align-items-center gap-3" (click)="open(editRoomModal)">
  <img src="/assets/images/icons/icon_redo.svg" alt="">
  <span class="font-primary--b">Cambiar nombre</span>
</button>
