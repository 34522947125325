<ng-container *ngIf="type === 'onBoarding';else defaultModal">
  <div class="onboarding">
    <app-onboarding-stepper [fnAction]="fnAction"></app-onboarding-stepper>
  </div>
</ng-container>
<ng-template #defaultModal>
  <div class="modal-header" #modal [class.modal-header-center]="type == 'notification'">
    <h4 class="font-header text-center">{{title}}</h4>
  </div>

  <div class="modal-body" *ngIf="content">
    <div class="font-regular" [class.modal-text-center]="type == 'notification'" [outerHTML]="content"></div>
  </div>

  <div class="modal-footer" [class.modal-footer-center]="type == 'notification'">
    <button style="background-color: #34AD82; color: white;" type="button"
      class="modal-footer--cta {{ content ? '' : 'mt-3' }}" (click)="closeAllModals()" [routerLink]="routeTo"
      *ngIf="routeTo; else elseBlock">
      {{ action? action : 'Ok.Entendido' }}
    </button>

    <ng-template #elseBlock>
      <button style="background-color: #34AD82; color: white;" type="button"
        class="modal-footer--cta {{ content ? '' : 'mt-3' }}" (click)="activeModal.close('done')">
        {{ action? action : 'Ok.Entendido' }}
      </button>
    </ng-template>
  </div>
</ng-template>