import {
  SetState, SetThemeColor
} from './ui.actions';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { UiStateModel } from './ui.models';

const UI_STATE_TOKEN = new StateToken<UiStateModel>(
  'ui'
);

@State<UiStateModel>({
  name: UI_STATE_TOKEN,
  defaults: {
    showDrawer: true,
    mainThemeColor: '#34AD82' //'#374052' //!GR THEME COLOR
  },
})
@Injectable()
export class UiState {
  @Action(SetState)
  setState(ctx: StateContext<UiStateModel>, action: SetState) {
    console.debug('action.payload', action.payload)
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showDrawer: action.payload,
    });
  }
  @Action(SetThemeColor)
  setThemeColor(ctx: StateContext<UiStateModel>, action: SetThemeColor) {
    console.debug('action.payload', action.payload)
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mainThemeColor: action.payload,
    });
  }

  @Selector()
  static showDrawer(state: UiStateModel) {
    return state.showDrawer;
  }

  @Selector()
  static mainThemeColor(state: UiStateModel) {
    return state.mainThemeColor;
  }
}
