import { OnChanges, SimpleChanges } from '@angular/core';
// video.component.ts
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
@Component({
  selector: 'app-video',
  template: `
    <video #target class="video-js" controls playsinline preload="none"></video>
  `,
  styleUrls: [
    './video.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target') target!: ElementRef;

  @Input() options!: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    controls: boolean,
    sources: {
      src: string,
      type?: string,
    }[],
  };

  player!: videojs.Player;

  constructor(
    private elementRef: ElementRef,
  ) { }
  ngOnInit() {
    // instantiate Video.js
    setTimeout(() => {
      console.debug('[VIDEO OPTIONS] ', this.options)
      this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() { });
    }, 500);
  }
  ngOnChanges(changes: SimpleChanges): void {

    setTimeout(() => {
      if (this.options) {
        // const { src, type } = this.options.sources[0]
        // this.player.src({ src, type })
        // this.player.load()
      }
      // this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      //   console.log('onPlayerReady', this);
      // });
    }, 500);
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
