import { ToastService } from './../../../utils/toast.service';
import { ErrorsService } from './../../../services/errors.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IRoom } from 'src/app/models/rooms';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  inProcess: boolean = false
  nextRoom: string | null = null
  @Output() loadRooms = new EventEmitter();
  @Input('rooms') rooms: Array<IRoom> = []
  @Input('selectedRoom') selectedRoom: IRoom | null = null
  @Input('student') student: any = null

  constructor(
    private modalService: NgbModal,
    private roomService: RoomsService,
    private errorsService: ErrorsService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true,
    })
  }

  editStudent() {
    this.inProcess = true
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    const data = {
      room_id: this.nextRoom
    }
    this.roomService.moveStudent(data, this.student.id).subscribe({
      next: (data) => {
        this.inProcess = false
        this.toastService.closeLoader();
        this.loadRooms.emit()
        console.debug(data)
      },
      error: (e) => {
        this.inProcess = false
        this.toastService.closeLoader();
        this.errorsService.handleErrors(e)
      },
    })
  }
  getRooms() {

  }
}
