import { Color } from '../../../models/color.models';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() color: Color = '#fff'
  @Input() isActive!: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
