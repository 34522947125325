import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Input() active: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.active = !this.active

  }

}
