<button class="button--showDrawer" [class.hiddenDrawer]="!showDrawer" (click)="toggleShow()">
  <i *ngIf="!showDrawer" class="bi bi-justify text-white"></i>
  <i *ngIf="showDrawer" class="bi bi-text-indent-right text-white"></i>
</button>

<div class="navbar-wrapper bg-white h-100" [ngClass]="{'show' : showDrawer, 'hide': !showDrawer }">
  <nav class="navbar" [class.slideMode]="sliderMode" [ngClass]="{'show' : showDrawer, 'hide': !showDrawer }">
    <a class="navbar__brand" routerLink="/dashboard/home">
      <img *ngIf="brandLogo" class="img-fluid" src="{{brandLogo}}" alt="">
      <img *ngIf="!brandLogo" class="icon" title="Growth Road" src="/assets/images/icons/icon-brand-optimized.png">
    </a>

    <ul class="navbar__options list-unstyled " [class.align-self-start]="institutionMode" #drawer__options>
      <li class="slider" #slider></li>
      <li *ngFor="let option of menuOptions" class="navbar__option" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: !option.dropDownOptions}" [title]="'Ir a '+option.title"
        (click)="changeRoute($event)">
        <a class="icon" [routerLink]="[option.link]">
          <img src="{{option.icon}}" alt="">
        </a>
      </li>
    </ul>

    <!-- <button *ngIf="!isSubscribed && !institutionMode; else callbackBlock" class="premium-btn" type="button"
      title="Ir al programa completo" [routerLink]="['/dashboard/membership']">
      <img src="/assets/images/icons/icon_premium.svg" alt="" class="icon">
      <p class="label text-white">Acceder al programa completo</p>
    </button>
    <ng-template #callbackBlock>
      <div class="premium-btn opacity-0" title="" [ngStyle]="{'cursor': 'auto'}">
        <img src="/assets/images/icons/icon_premium.svg" alt="" class="icon">
        <p class="label text-white">Acceder al programa completo</p>
      </div>
    </ng-template> -->

    <button class="btn" (click)="logoutHandler()" type="button" title="Cerrar Sesión">
      <img src="/assets/images/icons/icon_logout.png" alt="Cerrar Sesión" class="icon">
    </button>
  </nav>
</div>

<ng-template #content let-modal>
  <div class="modal-header bg-red">
    <h2 class="text-white contact-header font-18 font-primary--eb">¿Necesitas Ayuda?</h2>
  </div>
  <div class="modal-body">
    <p>Contacta directamente con nosotros:</p>
    <p>Mándanos un WhatsApp:</p>
    <a target="_blank" href="https://wa.me/602400455?text="
      class="text-decoration-none text-blue-anchor">+34602400455</a>
    <p>
      <span>Por redes sociales:</span>
    </p>
    <ul>
      <li>
        <a target="_blank" href="https://instagram.com/growthroad?igshid=YmMyMTA2M2Y="
          class="text-blue-anchor">Instagram</a>
      </li>
      <li>
        <a target="_blank" href="https://twitter.com/Growth_Road" class="text-blue-anchor">Twitter</a>
      </li>
      <li>
        <a target="_blank" href="https://www.facebook.com/profile.php?id=100086580546935"
          class="text-blue-anchor">Facebook</a>
      </li>
      <li>
        <a target="_blank" href="https://www.linkedin.com/company/growth-road/" class="text-blue-anchor">Linkedin</a>
      </li>
    </ul>
    <p class="mt-3">
      Para soporte técnico puedes acceder en el siguiente enlace:
    </p>
    <a target="_blank" href="https://growthroad.atlassian.net/servicedesk/customer/portal/3"
      class="text-blue-anchor">Soporte técnico</a>
  </div>
</ng-template>

<button class="contact" (click)="open(content)" *ngIf="!isSubscribed && !institutionMode">
  <img src="/assets/images/icons/icon_chat.svg" alt="">
</button>
