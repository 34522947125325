import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetThree, SetPage } from './three.actions';
import { ThreeStateModel } from './three.models';

const SIMPLE_VALUE_STATE_TOKEN = new StateToken<ThreeStateModel>(
  'three'
);

@State<ThreeStateModel>({
  name: SIMPLE_VALUE_STATE_TOKEN,
  defaults: {
    three: [],
    pageSelected: null
  },
})
@Injectable()
export class ThreeState {

  @Selector()
  static three(state: ThreeStateModel) {
    return state.three;
  }
  @Selector()
  static pageSelected(state: ThreeStateModel) {
    return state.pageSelected;
  }

  @Action(SetThree)
  setThree(ctx: StateContext<ThreeStateModel>, action: SetThree) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      three: action.payload,
    });
  }
  @Action(SetPage)
  setPage(ctx: StateContext<ThreeStateModel>, action: SetPage) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      pageSelected: action.payload,
    });
  }
}
