<ng-template #showRoomModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-blue font-primary--b" [style]="'color: '+mainColor+';'" id="modal-basic-title">
      {{room.name}}
    </h4>
    <span class="text-grey">
      {{notifications.length}} peticiones
    </span>
  </div>
  <div class="modal-body">
    <table class="table table-striped ">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Correo Electrónico</th>
          <th scope="col"><input type="checkbox" name="select_all" id="select_all" (click)="selectAll()"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let student of notifications; index as i">
          <td>
            {{student.participant_to_join.first_name}} {{student.participant_to_join.last_name}}
          </td>
          <td>{{student.participant_to_join.email}}</td>
          <td>
            <input type="checkbox" [name]="'user_' + i" [id]="'user_' + i" (click)="selectStudent(i)"
              [checked]="student.checked">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center" *ngIf="!notifications.length">
      <h2 class="font-18 font-primary--b py-3 opacity-75">No se han encontrado notificaciones</h2>
    </div>
  </div>
  <div class="modal-footer-custom pb-3" *ngIf="notifications.length">
    <div class="row">
      <div class="col-12 text-center">
        <button type="button" class="btn w-90 borda-5 py-2" [style]="'background-color: '+mainColor+';'"
          [disabled]="inProcess" (click)="joinRequestHandler(true)">
          <span class="text-white font-16" *ngIf="!inProcess">
            Aceptar
          </span>
          <app-loading *ngIf="inProcess" color="#fff"></app-loading>
        </button>
      </div>
      <div class="col-12 text-center mt-3">
        <button type="button" class="btn w-90 borda-5 py-2" [style]="'border:1px solid '+mainColor+';'"
          [disabled]="inProcess" (click)="joinRequestHandler(false)">
          <span class="font-16" [style]="'color: '+mainColor+';'" *ngIf="!inProcess">
            Rechazar
          </span>
          <app-loading *ngIf="inProcess" color="#fff"></app-loading>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<button class="btn d-flex align-items-center gap-3" (click)="open(showRoomModal)" type="button">
  <span class="icon-wrapper">
    <img src="/assets/images/icons/icon_book.svg" alt="">
    <div class="notifications-badge" *ngIf="haveNotifications">
    </div>
  </span>
  <span class="font-primary--b">Gestionar sala</span>
</button>